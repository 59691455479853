<template>
  <!-- <div class="vue-leaflet" style="position: absolute; width: 100%; height: 100%;"> -->
  <div>
    <l-map :zoom="zoom" :center="center">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker
        :lat-lng="item.marker"
        :key="index"
        v-for="(item,index) in leafletMarkers">
        <l-popup :content="item.text"></l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
// import * as Vue2Leaflet from 'vue2-leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
// import L from 'leaflet'
import 'leaflet/dist/leaflet.css';

// import iconUrl from 'leaflet/dist/images/marker-icon.png'
// import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

/* leaflet icon */
try {
  delete L.Icon.Default.prototype._getIconUrl
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  })
} catch (e) {}

export default {
  props: {
    markers: {
      default () {
        // 測試用資料
        return [{"lat":"25.0407668","lng":"121.5396627","text":"台北101"},{"lat":"25.0482773","lng":"121.5150377","text":"台北火車站"}]
      }
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  data () {
    return {
      zoom: 13,
      center: L.latLng(25.0407668, 121.5396627),
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // marker: L.latLng(47.413220, -1.219482),
      // text: 'this is a marker'
      leafletMarkers: []
    }
  },
  created () {
    // 取得多個座標點
    this.leafletMarkers = this.markers.map(d => {
      return {
        marker: L.latLng(d.lat, d.lng),
        text: d.text
      }
    })
    this.center = this.leafletMarkers[0].marker
  }
}
</script>
