<template>
  <LeafletMap
    class="vue-leaflet"
    style="position: absolute; width: 100%; height: 100%;"
    v-if="markers.length"
    :markers="markers">
  </LeafletMap>
</template>

<script>
import LeafletMap from '@/components/LeafletMap/LeafletMap.vue'
import * as apis from '@/apis/index.ts'
import { SELF_UNIID } from '@/const/codes.ts'
import { MessageBox, Message } from 'element-ui'

export default {
  data () {
    return {
      markers: []
    }
  },
  components: {
    LeafletMap
  },
  methods: {
    async handleError () {
      Message({
        message: '未知錯誤，請確認網路狀態或聯絡系統管理員',
        type: 'error',
        duration: 5 * 1000
      })
      await new Promise(resolve => setTimeout(resolve, 1000))
      // 無資料引導回首頁
      this.$router.replace({ name: 'home' })
    },
    async showCompany (uniID) {
      const result = await apis.companyGetLocation({ uniID })
      if (!result.payload || !result.payload.company) {
        this.handleError()
        return
      }
      const targetCompany = result.payload.company

      this.markers = [
        {
          lat: targetCompany.lat,
          lng: targetCompany.lng,
          text: `<b>${targetCompany.company_name}</b><br>${targetCompany.company_address}`
        }
      ]
    },
    async showCompanyAndSelfBranch (uniID) {
      const promiseTarget = apis.companyGetLocation({ uniID })
      const promiseSelf = apis.companyGetLocation({ uniID: SELF_UNIID })
      const result = await Promise.all([promiseTarget, promiseSelf])
      const targetResult = result[0].payload
      const selfResult = result[1].payload
      if (!targetResult || !targetResult.branchs || !selfResult || !selfResult.company) {
        this.handleError()
        return
      }

      const selfCompanyName = selfResult.company.company_name
      const selfBranchs = selfResult.branchs
      const targetCompany = targetResult.company
      
      // 三角函數算距離
      const getCPow = (aItem, bItem) => {
        const a = Math.abs(aItem.lat - bItem.lat)
        const b = Math.abs(aItem.lng - bItem.lng)
        return Math.pow(a, 2) + Math.pow(b, 2) // 只有要比較沒真的要算出距離，所以c就不取平方了
      }
      
      // 取得與公司（查詢的）座標最近的分公司（自己的）
      let minDistanceBranch = selfBranchs[0]
      for (let i = 1; i < selfBranchs.length; i++) {
        const compareItem = selfBranchs[i]
        if (getCPow(targetCompany, compareItem) < getCPow(targetCompany, minDistanceBranch)) {
          minDistanceBranch = compareItem
        }
      }
      
      this.markers = [
        {
          lat: minDistanceBranch.lat,
          lng: minDistanceBranch.lng,
          text: `<b>${selfCompanyName}</b>
            <br>
            <b>${minDistanceBranch.company_name}</b>
            <br>
            ${minDistanceBranch.company_address}`
        },
        {
          lat: targetCompany.lat,
          lng: targetCompany.lng,
          text: `<b>${targetCompany.company_name}</b>
          <br>
          ${targetCompany.company_address}`
        }
      ]
    }
  },
  async created () {
    if (!this.$route.query.uniID) {
      // 無選單索引導回首頁
      this.$router.replace({ name: 'home' })
    }
    if (this.$route.query.nearestBranch == 'true') {
      // 定位公司座標及最近的自己的分公司
      this.showCompanyAndSelfBranch(this.$route.query.uniID)
    } else {
      // 只定位公司座標
      this.showCompany(this.$route.query.uniID)
    }
    
  }
}
</script>
